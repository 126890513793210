@use '~/src/lib/_root';

@mixin center-column {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@mixin row($gap: 0) {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: $gap;
}

@mixin absolute-fill {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin max-lines($lines) {
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin clip-corners($radius: 14px) {
	clip-path: polygon($radius 0, 100% 0, 100% calc(100% - $radius), calc(100% - $radius) 100%, 0 100%, 0 $radius);
}

@mixin bordered {
	border: 1px solid var(--color-brand-primary);
	@include rounded();
}

@mixin rounded {
	border-radius: 8px;
	overflow: hidden;
}

@mixin card($padding: var(--size-gap)) {
	border: 1px solid var(--color-offwhite);
	padding: $padding;
	@include rounded;
}

@mixin large-desktop {
	@media (min-width: calc(root.$size-large-desktop-width)) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: calc(root.$size-laptop-width + 1px)) {
		@content;
	}
}

@mixin laptop {
	@media (min-width: root.$size-tablet-width) and (max-width: root.$size-laptop-width) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: root.$size-mobile-width) and (max-width: root.$size-tablet-width) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: root.$size-mobile-width) {
		@content;
	}
}

@mixin mobile-padded {
	padding: var(--size-gap-2x);
	@include desktop {
		padding: 0;
	}
}
