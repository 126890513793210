.PhoneInput {
	input {
		transition: all 0.1s ease;
		margin: 0;
		outline: none;
		border: 1px solid var(--color-offwhite);
		border-radius: 8px;
		padding: var(--size-gap-quarter) var(--size-gap-half);
		width: 100%;
		font: var(--body-m);

		&:hover,
		&:focus {
			border-color: var(--color-gray);
		}
	}
}
