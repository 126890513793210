@use '~/src/lib/mixins';

.Index {
	.Tiles {
		grid-gap: var(--size-gap);
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-top: var(--size-gap);

		@include mixins.mobile {
			grid-template-columns: repeat(1, 1fr);
		}

		@include mixins.tablet {
			grid-template-columns: repeat(1, 1fr);
		}

		& a,
		& a:visited {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			background: var(--color-offwhite);
			color: var(--color-black);
			font-weight: bold;
			font-size: 24px;
			text-decoration: none;
			& div {
				margin-bottom: var(--size-gap-2x);
			}
		}

		& img {
			margin-top: var(--size-gap-2x);
			margin-bottom: var(--size-gap-2x);
			border-radius: 24px;
			aspect-ratio: 1;
			width: 100px;
		}
	}
}
