@use '~/src/lib/mixins';

.Login {
	display: flex;
	grid-column: full;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	margin-top: var(--size-gap);
	width: 100%;
	.Card {
		@include mixins.card;
		max-width: 20em;
	}
	.PhoneForm {
		@include mixins.center-column;
		.FormContainer {
			display: flex;
			margin: 12px;
		}
	}
	.CodeForm {
		@include mixins.center-column;
		input {
			margin-top: var(--size-gap-half);
			padding: 4px;
			width: 6em;
			font-size: 18px;
			text-align: center;
		}
		.Buttons {
			display: flex;
			flex-direction: column;
			gap: var(--size-gap);
			margin-top: var(--size-gap);
		}
	}
	.UserForm {
		@include mixins.center-column;
		input {
			margin: var(--size-gap);
			padding: var(--size-gap-half);
		}
	}
}
