//This file is automatically generated by the build process
//Edit styles.json then run `npm run build` to update
$color-brand-primary: #141923;
$color-brand-primary-dark: #0c111b;
$color-brand-primary-modal: rgba(20,25,35,0.98);
$color-brand-primary-overlay: rgba(20,25,35,0.7);
$color-brand-secondary: #ff6e40;
$color-brand-link: #0fb5a7;
$color-offblack: #333;
$color-black: #1e1e1e;
$color-true-black: #000;
$color-white: #ffffff;
$color-offwhite: #efefef;
$color-dark-gray: #373737;
$color-gray: #878787;
$color-light-gray: #cccccc;
$color-green: #1feb9e;
$color-red: #ff5549;
$color-blue: #128dff;
$color-yellow: #fff354;
$color-purple: #7d5dfc;
$color-desaturated-white-border: rgba(255,255,255,0.2);
$color-desaturated-white-text: rgba(255,255,255,0.4);
$color-desaturated-white-background: rgba(255,255,255,0.8);
$color-desaturated-black-border: rgba(0,0,0,0.1);
$color-desaturated-black-text: rgba(0,0,0,0.4);
$color-transparent-black: rgba(0,0,0,0.3);
$color-shadow: rgba(0,0,0,0.25);
$color-inner-shadow: rgba(0,0,0,0.05);
$font-body: 400 14px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-sb: 600 14px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-b: 700 14px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-s: 400 12px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-s-sb: 600 12px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-s-b: 700 12px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-m: 400 16px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-m-sb: 600 16px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-m-b: 700 16px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-l: 400 18px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-l-sb: 600 18px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-l-b: 700 18px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-xl: 400 24px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-xl-sb: 600 24px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-body-xl-b: 700 24px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
$font-highlight: oswald, helvetica neue, helvetica, sans-serif;
$font-mono-s: 12px source code pro, monospace;
$font-mono-s-b: 700 12px source code pro, monospace;
$font-mono: 14px source code pro, monospace;
$font-mono-b: 700 14px source code pro, monospace;
$size-gap: var(--gap);
$size-gap-quarter: calc(var(--gap) / 4);
$size-gap-half: calc(var(--gap) / 2);
$size-gap-2x: calc(var(--gap) * 2);
$size-gap-3x: calc(var(--gap) * 3);
$size-gap-4x: calc(var(--gap) * 4);
$size-gap-6x: calc(var(--gap) * 6);
$size-row-gap: var(--row-gap);
$size-content-width: 1400px;
$size-large-content-width: 1720px;
$size-mobile-width: 640px;
$size-tablet-width: 1024px;
$size-laptop-width: 1440px;
$size-desktop-width: 1440px;
$size-large-desktop-width: 1740px;
:root {
--color-brand-primary: #141923;
--color-brand-primary-dark: #0c111b;
--color-brand-primary-modal: rgba(20,25,35,0.98);
--color-brand-primary-overlay: rgba(20,25,35,0.7);
--color-brand-secondary: #ff6e40;
--color-brand-link: #0fb5a7;
--color-offblack: #333;
--color-black: #1e1e1e;
--color-true-black: #000;
--color-white: #ffffff;
--color-offwhite: #efefef;
--color-dark-gray: #373737;
--color-gray: #878787;
--color-light-gray: #cccccc;
--color-green: #1feb9e;
--color-red: #ff5549;
--color-blue: #128dff;
--color-yellow: #fff354;
--color-purple: #7d5dfc;
--color-desaturated-white-border: rgba(255,255,255,0.2);
--color-desaturated-white-text: rgba(255,255,255,0.4);
--color-desaturated-white-background: rgba(255,255,255,0.8);
--color-desaturated-black-border: rgba(0,0,0,0.1);
--color-desaturated-black-text: rgba(0,0,0,0.4);
--color-transparent-black: rgba(0,0,0,0.3);
--color-shadow: rgba(0,0,0,0.25);
--color-inner-shadow: rgba(0,0,0,0.05);
--font-body: 400 14px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-sb: 600 14px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-b: 700 14px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-s: 400 12px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-s-sb: 600 12px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-s-b: 700 12px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-m: 400 16px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-m-sb: 600 16px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-m-b: 700 16px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-l: 400 18px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-l-sb: 600 18px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-l-b: 700 18px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-xl: 400 24px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-xl-sb: 600 24px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-body-xl-b: 700 24px/1.2 source sans pro, helvetica neue, helvetica, sans-serif;
--font-highlight: oswald, helvetica neue, helvetica, sans-serif;
--font-mono-s: 12px source code pro, monospace;
--font-mono-s-b: 700 12px source code pro, monospace;
--font-mono: 14px source code pro, monospace;
--font-mono-b: 700 14px source code pro, monospace;
--size-gap: var(--gap);
--size-gap-quarter: calc(var(--gap) / 4);
--size-gap-half: calc(var(--gap) / 2);
--size-gap-2x: calc(var(--gap) * 2);
--size-gap-3x: calc(var(--gap) * 3);
--size-gap-4x: calc(var(--gap) * 4);
--size-gap-6x: calc(var(--gap) * 6);
--size-row-gap: var(--row-gap);
--size-content-width: 1400px;
--size-large-content-width: 1720px;
--size-mobile-width: 640px;
--size-tablet-width: 1024px;
--size-laptop-width: 1440px;
--size-desktop-width: 1440px;
--size-large-desktop-width: 1740px;
}
