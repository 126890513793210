@use '~/src/lib/mixins';

.Content {
	@include mixins.large-desktop {
		--content-width: calc(var(--app-width) - var(--size-gap-4x) - var(--size-gap) - 250px);
	}
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: var(--size-gap-2x);
	max-width: var(--content-width);
	@include mixins.mobile {
		padding: var(--size-gap);
	}
	// Default layout of pages
	> div {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		max-width: var(--content-width);
	}
}
